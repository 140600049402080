import http from "../http-common";

export const uploadFile = (
  file,
  sessionId,
  username,
  password,
  onUploadProgress
) => {
  let formData = new FormData();
  //console.log("uploadFile", "sessionId", sessionId);
  formData.append("sessionId", sessionId);
  formData.append("firmware", file);

  return http.post("/upload-firmware-2811", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    auth: { username: username, password: password },
    onUploadProgress,
  });
};

export const getFiles = (seesionId, username, password) => {
  return http.get(`/report-2811?sessionId=${seesionId}`, {
    auth: { username: username, password: password },
  });
};

export const authentivate = (username, password) => {
  return http.get(`/auth-2811`, {
    auth: { username: username, password: password },
  });
};
