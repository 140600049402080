import React, { useState, useEffect } from "react";
import { authentivate } from "../services/FileUploadService";
import "./style.scss";

const AuthenticationCMP = ({ onAuthenticate }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    let username = e.target.username.value;
    let password = e.target.password.value;

    if (!username) {
      alert("username is required");
    } else if (!password) {
      alert("password is required");
    }

    authentivate(username, password).then((response) => {
      if (response.data.status === "auth") {
        onAuthenticate(username, password);
      }
    });
  };

  return (
    <div className="auth-cmp">
      <form className="form" onSubmit={handleSubmit}>
        <div className="input-group">
          <label htmlFor="username">Username</label>
          <input type="username" name="username" placeholder="username" />
        </div>
        <div className="input-group">
          <label htmlFor="password">Password</label>
          <input type="password" name="password" />
        </div>
        <button className="primary">Login</button>
      </form>
    </div>
  );
};

export default AuthenticationCMP;
