import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import { getFiles, uploadFile } from "../services/FileUploadService";
import ReactLoading from "react-loading";
import moment from "moment";
const UploadFiles = ({ username, password }) => {
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [fileInfos, setFileInfos] = useState([]);
  const [sessionId, setSessionId] = useState(uuidv4());
  const [report, setReport] = useState({ __html: "" });
  const [reportEnd, setReportEnd] = useState("");

  let fetch_interval;
  useEffect(() => {
    fetch_interval = setInterval(() => {
      localStorage.setItem("token", moment().format());
      if (reportEnd === "inprogress") {
        getFiles(sessionId, username, password).then((response) => {
          //console.log(response.data);
          let status = response.data.status;
          if (status == "end") {
            clearInterval(fetch_interval);
          }

          setReportEnd(response.data.status);
          setReport({ __html: response.data.content });
        });
      }
    }, 5000);

    return () => clearInterval(fetch_interval);
  }, [reportEnd]);

  const upload = () => {
    let currentFile = selectedFiles[0];

    setProgress(0);
    setCurrentFile(currentFile);

    uploadFile(currentFile, sessionId, username, password, (event) => {
      //console.log("uploadFile", "sessionId", sessionId);
      setProgress(Math.round((100 * event.loaded) / event.total));
    })
      .then((response) => {
        setReportEnd("inprogress");
        setMessage(response.data.message);
        setSessionId(response.data.sessionId);
        return getFiles(sessionId, username, password);
      })
      .then((files) => {
        setReportEnd(files.data.status);
        setReport({ __html: files.data.content });
      })
      .catch(() => {
        setProgress(0);
        setMessage("Could not upload the file!");
        setCurrentFile(undefined);
      });

    setSelectedFiles(undefined);
  };

  const onDrop = (files) => {
    if (files.length > 0) {
      setSelectedFiles(files);
    }
  };

  const renderReport = () => {
    let elm = [];
    if (reportEnd === "inprogress") {
      elm.push(<ReactLoading type={"spin"} color="#000" />);
    } else {
      elm.push(<div dangerouslySetInnerHTML={report} />);
    }
    return <div key={uuidv4()}>{elm}</div>;
  };

  return (
    <div>
      {currentFile && (
        <div className="progress mb-3">
          <div
            className="progress-bar progress-bar-info progress-bar-striped"
            role="progressbar"
            aria-valuenow={progress}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: progress + "%" }}
          >
            {progress}%
          </div>
        </div>
      )}

      <Dropzone onDrop={onDrop} multiple={true}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              {selectedFiles && selectedFiles[0].name ? (
                <div className="selected-file">
                  {selectedFiles && selectedFiles[0].name}
                </div>
              ) : (
                "Drag and drop file here, or click to select file"
              )}
            </div>
            <aside className="selected-file-wrapper">
              <button
                className="btn btn-success"
                disabled={!selectedFiles}
                onClick={upload}
              >
                Upload
              </button>
            </aside>
          </section>
        )}
      </Dropzone>

      <div className="alert alert-light" role="alert">
        {message}
      </div>

      {renderReport()}
    </div>
  );
};

export default UploadFiles;
