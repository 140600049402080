import React, { useState, useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import FileUpload from "./components/FileUpload";
import AuthenticationCMP from "./components/AuthenticationCMP";
import moment from "moment";

const getCredentials = () => {
  let token = localStorage.getItem("token") || false;
  let username = localStorage.getItem("username") || false;
  let password = localStorage.getItem("password") || false;
  let isAuthenticated = false;
  if (token) {
    let startTime = moment(token);
    let end = moment();
    var duration = moment.duration(end.diff(startTime));
    //console.log(duration.asMinutes());
    if (duration.asMinutes > 15) {
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("password");
      return { t: false, u: false, p: false };
    } else {
      return { t: true, u: username, p: password };
    }
  }
  return { t: isAuthenticated, u: username, p: password };
};

function App() {
  const { t, u, p } = getCredentials();
  const [isAuthenticated, setAuthenticated] = useState(t);
  const [username, setUsername] = useState(u);
  const [password, setPassword] = useState(p);

  const onAuthenticate = (u, p) => {
    localStorage.setItem("token", moment().format());
    localStorage.setItem("username", u);
    localStorage.setItem("password", p);
    setPassword(p);
    setUsername(u);
    setAuthenticated(true);
  };

  const handlelogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("password");
    setPassword(false);
    setUsername(false);
    setAuthenticated(false);
  };
  const renderCMP = () => {
    let elm = [];
    //console.log(isAuthenticated, username, password);
    if (isAuthenticated)
      elm.push(
        <>
          <h4>Drag & Drop Firmware file</h4>
          <FileUpload username={username} password={password} />
        </>
      );
    else
      elm.push(
        <div
          style={{ width: " 100%", display: "flex", justifyContent: "center" }}
        >
          <AuthenticationCMP onAuthenticate={onAuthenticate} />{" "}
        </div>
      );

    return <>{elm}</>;
  };
  return (
    <div className="container" style={{ width: "1000px" }}>
      <div className="my-3">
        <h1>CyberEmultion Demo v0.1</h1>
        {isAuthenticated && (
          <div
            style={{
              width: "100%",
              height: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              cursor: "pointer",
            }}
          >
            <div onClick={handlelogout}>logout</div>
          </div>
        )}
        {renderCMP()}
      </div>
    </div>
  );
}

export default App;
